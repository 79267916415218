import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./categoryDropdown.css";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    variant="light"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </Button>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled categories-list">
          {React.Children.toArray(children).filter(
            (child) =>
              !value ||
              child.props.children.toLowerCase().includes(value.toLowerCase())
          )}
        </ul>
      </div>
    );
  }
);

function CategoryDropdown({ listingCategories, setSearchCategory }) {
  const [selectedCategory, setSelectedCategory] = useState("");

  const categorySelected = (key) => {
    setSelectedCategory(key);
    setSearchCategory(key);
  };

  return (
    <Dropdown className="p-2">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {selectedCategory
          ? "Category selected: " + listingCategories[selectedCategory].name
          : "Select category"}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {Object.keys(listingCategories).map((key) => (
          <Dropdown.Item key={key} onClick={() => categorySelected(key)}>
            {listingCategories[key].parent_id
              ? listingCategories[listingCategories[key].parent_id].name +
                " > " +
                listingCategories[key].name
              : listingCategories[key].name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default CategoryDropdown;
