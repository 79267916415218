import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import "../../App.css";

function GetListingResults({ mainListing, listingCategories }) {
  function getCategory(taxonomyId) {
    return listingCategories[taxonomyId];
  }

  return (
    <div className="p-2">
      {mainListing.title && (
        <div>
          <Stack direction="horizontal" gap={2}>
            <h5>Title:</h5>
            <h5>
              <Badge pill bg="secondary">
                {mainListing.title}
              </Badge>
            </h5>
          </Stack>

          <Stack direction="horizontal" gap={2}>
            <h5>Category:</h5>
            <h5>
              <Badge pill bg="success">
                {getCategory(mainListing.taxonomy_id)}
              </Badge>
            </h5>
          </Stack>
          <div className="text-left">
            <h5>Tags:</h5>
            {mainListing.tags.map((tag, index) => (
              <Badge
                key={index}
                bg="warning"
                text="dark"
                className="m-1 float-left"
              >
                <h6>{tag}</h6>
              </Badge>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default GetListingResults;
