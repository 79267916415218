import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import "./searchListings.css";
import CategoryDropdown from "../categoryDropdown/categoryDropdown";

function SearchListingsForm({
  setListings,
  lockedSearch,
  taxonomyId,
  byCategory,
  listingCategories,
}) {
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [searchTerms, setSearchTerms] = useState("");
  const [searchCategory, setSearchCategory] = useState("");

  const handleSearchTermsChange = (e) => setSearchTerms(e.target.value);

  const searchListingsWithTaxonomyId = async (event) => {
    if (event) {
      event.preventDefault();
    }

    setLoadingSearchResults(true);
    console.log(
      `Called searchListings with searchTerms: ${searchTerms} and taxonomyId: ${searchCategory}`
    );

    const res = await fetch(
      `/api/search?searchTerms=${searchTerms}&taxonomyId=${searchCategory}`
    );
    const data = await res.json();

    // console.log(`Search results: ${JSON.stringify(data.searchResults)}`);
    setListings(data.searchResults);
    setLoadingSearchResults(false);
  };

  const searchListings = async (event) => {
    event.preventDefault();

    setLoadingSearchResults(true);
    console.log(`Called searchListings with searchTerms: ${searchTerms}`);

    const res = await fetch(`/api/search?searchTerms=${searchTerms}`);
    const data = await res.json();

    // console.log(`Search results: ${JSON.stringify(data.searchResults)}`);
    setListings(data.searchResults);
    setLoadingSearchResults(false);
  };

  useEffect(() => {
    if (taxonomyId) {
      searchListingsWithTaxonomyId();
      setSearchCategory(taxonomyId);
    }
    // eslint-disable-next-line
  }, [taxonomyId]);

  return (
    <>
      <div className="searchContainer d-flex justify-content-center align-items-center">
        <Form
          className="rounded p-4 p-sm-3 d-flex flex-row"
          onSubmit={
            searchCategory ? searchListingsWithTaxonomyId : searchListings
          }
        >
          {byCategory ? (
            <CategoryDropdown
              listingCategories={listingCategories}
              setSearchCategory={setSearchCategory}
            />
          ) : (
            <Form.Control
              placeholder="Enter search terms"
              value={searchTerms}
              onChange={handleSearchTermsChange}
              disabled={loadingSearchResults || lockedSearch}
            />
          )}
          <Button
            variant="secondary"
            type="submit"
            disabled={loadingSearchResults || lockedSearch}
            className="text-nowrap"
          >
            {!loadingSearchResults ? (
              "Search"
            ) : (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Form>
      </div>
    </>
  );
}

export default SearchListingsForm;
