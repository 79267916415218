import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./searchListings.css";
import "../../App.css";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

function SearchListingsResults({ listings, listingCategories }) {
  const SORT_BY_RANK = "sort by rank";
  const SORT_BY_RANK_ASC = "sort by rank ascending";
  const SORT_BY_RANK_DESC = "sort by rank descending";
  const SORT_BY_VIEWS = "sort by views";
  const SORT_BY_VIEWS_ASC = "sort by views ascending";
  const SORT_BY_VIEWS_DESC = "sort by views descending";
  const SORT_BY_FAVORERS = "sort by favorers";
  const SORT_BY_FAVORERS_ASC = "sort by favorers ascending";
  const SORT_BY_FAVORERS_DESC = "sort by favorers descending";
  const [results, setResults] = useState(null);
  const [resultsVisible, setResultsVisible] = useState(10);
  const [sortBy, setSortBy] = useState(SORT_BY_RANK_ASC);

  function getCategory(taxonomyId) {
    return listingCategories[taxonomyId].name;
  }

  function loadMoreResults() {
    setResultsVisible(resultsVisible + 10);
  }

  function addRankToListings(listings) {
    // Check if the rank has been applied already
    if (listings[0].rank) {
      return;
    }
    listings.forEach((listing, index) => {
      listing.rank = index + 1;
    });
  }

  function toggleSort(sortType) {
    if (sortType === SORT_BY_RANK) {
      setSortBy(
        sortBy === SORT_BY_RANK_ASC ? SORT_BY_RANK_DESC : SORT_BY_RANK_ASC
      );
    } else if (sortType === SORT_BY_VIEWS) {
      setSortBy(
        sortBy === SORT_BY_VIEWS_DESC ? SORT_BY_VIEWS_ASC : SORT_BY_VIEWS_DESC
      );
    } else if (sortType === SORT_BY_FAVORERS) {
      setSortBy(
        sortBy === SORT_BY_FAVORERS_DESC
          ? SORT_BY_FAVORERS_ASC
          : SORT_BY_FAVORERS_DESC
      );
    }
  }

  function sortListings() {
    if (results) {
      if (sortBy === SORT_BY_RANK_ASC) {
        setResults([...results].sort((b, a) => b.rank - a.rank));
      } else if (sortBy === SORT_BY_RANK_DESC) {
        setResults([...results].sort((b, a) => a.rank - b.rank));
      } else if (sortBy === SORT_BY_VIEWS_ASC) {
        setResults([...results].sort((b, a) => b.views - a.views));
      } else if (sortBy === SORT_BY_VIEWS_DESC) {
        setResults([...results].sort((b, a) => a.views - b.views));
      } else if (sortBy === SORT_BY_FAVORERS_ASC) {
        setResults(
          [...results].sort((b, a) => b.num_favorers - a.num_favorers)
        );
      } else if (sortBy === SORT_BY_FAVORERS_DESC) {
        setResults(
          [...results].sort((b, a) => a.num_favorers - b.num_favorers)
        );
      }
    }
  }

  useEffect(() => {
    if (listings.length > 0) {
      addRankToListings(listings);
      setResults(listings);
    }
  }, [listings]);

  useEffect(() => {
    sortListings();
    // eslint-disable-next-line
  }, [sortBy]);

  return (
    <div className="resultsContainer">
      {results && (
        <>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Listing</th>
                <th>Title</th>
                <th>Category</th>
                <th>
                  <Button
                    variant="outline-dark"
                    onClick={() => toggleSort(SORT_BY_RANK)}
                  >
                    Rank
                    {sortBy === SORT_BY_RANK_ASC ? (
                      <FaSortUp />
                    ) : sortBy === SORT_BY_RANK_DESC ? (
                      <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </Button>
                </th>
                <th>
                  <Button
                    variant="outline-dark"
                    onClick={() => toggleSort(SORT_BY_VIEWS)}
                  >
                    Views
                    {sortBy === SORT_BY_VIEWS_ASC ? (
                      <FaSortUp />
                    ) : sortBy === SORT_BY_VIEWS_DESC ? (
                      <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </Button>
                </th>
                <th>
                  <Button
                    variant="outline-dark"
                    onClick={() => toggleSort(SORT_BY_FAVORERS)}
                  >
                    Favorited
                    {sortBy === SORT_BY_FAVORERS_ASC ? (
                      <FaSortUp />
                    ) : sortBy === SORT_BY_FAVORERS_DESC ? (
                      <FaSortDown />
                    ) : (
                      <FaSort />
                    )}
                  </Button>
                </th>
                <th>Tags</th>
                <th>Link</th>
              </tr>
            </thead>

            <tbody>
              {results.slice(0, resultsVisible).map((listing, index) => (
                <tr key={index}>
                  <td className="listingImg">
                    <Card>
                      <Card.Img
                        variant="top"
                        src={listing.images[0].url_170x135}
                      />
                    </Card>
                  </td>
                  <td className="listingTitle">{listing.title}</td>
                  <td>
                    <Badge bg="success">
                      {getCategory(listing.taxonomy_id)}
                    </Badge>
                  </td>
                  <td>{listing.rank}</td>
                  <td>{listing.views.toLocaleString()}</td>
                  <td>{listing.num_favorers.toLocaleString()}</td>

                  <td>
                    <div>
                      {listing.tags.map((tag, tagIndex) => (
                        <Badge
                          key={tagIndex}
                          bg="warning"
                          text="dark"
                          className="m-1 float-left"
                        >
                          <h6>{tag}</h6>
                        </Badge>
                      ))}{" "}
                    </div>
                  </td>

                  <td>
                    {/* eslint-disable-next-line */}
                    <a href={listing.url} target="_blank">
                      Link to listing
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button
            variant="secondary"
            size="lg"
            className="m-2"
            onClick={loadMoreResults}
            disabled={resultsVisible >= results.length}
          >
            Load more
          </Button>
        </>
      )}
    </div>
  );
}

export default SearchListingsResults;
