import { useState } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import "./getListing.css";
import "../../App.css";

import ReactGA from "react-ga4";

function GetListingForm({ setMainListing }) {
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [listingId, setListingId] = useState("");

  const handleFormChange = (e) => setListingId(e.target.value);

  const searchListings = async (event) => {
    event.preventDefault();
    setLoadingSearchResults(true);
    console.log(`Called searchListings with: ${listingId}`);

    const res = await fetch(`/api/listing?listingId=${listingId}`);
    const data = await res.json();

    // console.log(`Response: ${JSON.stringify(data.listingDetails)}`);
    setMainListing(data.listingDetails);
    setLoadingSearchResults(false);

    ReactGA.event({
      category: "Button Click",
      action: "Called searchListings",
      label: "Tags Page",
    });
  };

  return (
    <>
      <div className="searchContainer d-flex justify-content-center align-items-center">
        <Form
          className="rounded p-4 p-sm-3 d-flex flex-row"
          onSubmit={searchListings}
        >
          <Form.Control
            placeholder="Enter listing id"
            value={listingId}
            onChange={handleFormChange}
          />
          <Button
            variant="secondary"
            type="submit"
            disabled={loadingSearchResults}
            className="text-nowrap"
          >
            {!loadingSearchResults ? (
              "Search"
            ) : (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Form>
      </div>
    </>
  );
}

export default GetListingForm;
