import "./TagsKeywords.css";
import { useState, useEffect } from "react";
import Stack from "react-bootstrap/Stack";

import SearchListingsForm from "../../components/searchListings/searchListingsForm";

import SearchListingsResults from "../../components/searchListings/searchListingsResults";
import "bootstrap/dist/css/bootstrap.min.css";

import ReactGA from "react-ga4";

function TagsKeywords() {
  const [listingsByKeywords, setListingsByKeywords] = useState([]);
  const [listingCategories, setListingCategories] = useState([]);

  useEffect(() => {
    console.log("The tags page has been loaded. Getting the seller taxonomy");
    ReactGA.send({ hitType: "pageview", page: "/tags", title: "Tags page" });
    getSellerTaxonomy();
  }, []);

  const getSellerTaxonomy = async () => {
    const res = await fetch(`/api/seller-taxonomy`);
    const data = await res.json();
    setListingCategories(data.listingCategories);
  };

  return (
    <Stack gap={3} className="mainContainer">
      <header className="App-header p-2">
        Explore alternative tags for your listings. Search for similar listings
        using relevant keywords.
      </header>
      <div className="mx-auto accordionContainer">
        <SearchListingsForm setListings={setListingsByKeywords} />
        <SearchListingsResults
          listings={listingsByKeywords}
          listingCategories={listingCategories}
        />
      </div>
    </Stack>
  );
}

export default TagsKeywords;
