import "./Tags.css";
import { useState, useEffect } from "react";
import Stack from "react-bootstrap/Stack";
import Accordion from "react-bootstrap/Accordion";
import SearchListingsForm from "../../components/searchListings/searchListingsForm";
import GetListingForm from "../../components/getListing/getListingForm";
import GetListingResults from "../../components/getListing/getListingResults";
import SearchListingsResults from "../../components/searchListings/searchListingsResults";
import "bootstrap/dist/css/bootstrap.min.css";

import ReactGA from "react-ga4";

function Tags() {
  const [mainListing, setMainListing] = useState([]);
  const [listingsByCategory, setListingsByCategory] = useState([]);
  const [listingsByKeywords, setListingsByKeywords] = useState([]);
  const [listingCategories, setListingCategories] = useState([]);

  useEffect(() => {
    console.log("The tags page has been loaded. Getting the seller taxonomy");
    ReactGA.send({ hitType: "pageview", page: "/tags", title: "Tags page" });
    getSellerTaxonomy();
  }, []);

  const getSellerTaxonomy = async () => {
    const res = await fetch(`/api/seller-taxonomy`);
    const data = await res.json();
    setListingCategories(data.listingCategories);
  };

  return (
    <Stack gap={3} className="mainContainer">
      <header className="App-header p-2">
        <p>Explore alternative tags for your listings</p>
      </header>
      <div className="mx-auto accordionContainer">
        <Accordion className="p-2 w-100" defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Let's start by selecting one of your listings
            </Accordion.Header>
            <Accordion.Body>
              <GetListingForm setMainListing={setMainListing} />
              <GetListingResults
                mainListing={mainListing}
                listingCategories={listingCategories}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Explore tags used by listings from the same category (ordered by
              the number of views)
            </Accordion.Header>
            <Accordion.Body>
              <SearchListingsForm
                setListings={setListingsByCategory}
                lockedSearch="True"
                taxonomyId={mainListing.taxonomy_id}
              />
              <SearchListingsResults
                listings={listingsByCategory}
                listingCategories={listingCategories}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Find tags by searching for listings using relevant keywords
              (ordered by the number of views)
            </Accordion.Header>
            <Accordion.Body>
              <SearchListingsForm setListings={setListingsByKeywords} />
              <SearchListingsResults
                listings={listingsByKeywords}
                listingCategories={listingCategories}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </Stack>
  );
}

export default Tags;
