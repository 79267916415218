import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { HashRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import Tags from "./pages/Tags/Tags";
import Contact from "./pages/Contact/Contact";
import NavigationBar from "./components/navigationbar";
import TagsCategory from "./pages/TagsCategory/TagsCategory";
import TagsKeywords from "./pages/TagsKeywords/TagsKeywords";
import Tracking from "./pages/Tracking/Tracking";
<script
  src="https://cdn.jsdelivr.net/npm/react/umd/react.production.min.js"
  crossorigin
></script>;

function App() {
  return (
    <div className="App">
      <NavigationBar></NavigationBar>
      <HashRouter>
        <Routes>
          <Route path="/*" index element={<Home />} />
          <Route path="/tags" index element={<Tags />} />
          <Route path="/tags/category" index element={<TagsCategory />} />
          <Route path="/tags/keywords" index element={<TagsKeywords />} />
          <Route path="/tracking" index element={<Tracking />} />
          <Route path="/contactus" index element={<Contact />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
