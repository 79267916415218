import "./TagsCategory.css";
import { useState, useEffect } from "react";
import Stack from "react-bootstrap/Stack";

import SearchListingsForm from "../../components/searchListings/searchListingsForm";

import SearchListingsResults from "../../components/searchListings/searchListingsResults";
import "bootstrap/dist/css/bootstrap.min.css";

import ReactGA from "react-ga4";

function TagsCategory() {
  const [listingsByKeywords, setListingsByKeywords] = useState([]);
  const [listingCategories, setListingCategories] = useState([]);

  useEffect(() => {
    console.log(
      "The tags category page has been loaded. Getting the seller taxonomy"
    );
    ReactGA.send({
      hitType: "pageview",
      page: "/tags/category",
      title: "Tags-category page",
    });
    getSellerTaxonomy();
  }, []);

  const getSellerTaxonomy = async () => {
    const res = await fetch(`/api/seller-taxonomy`);
    const data = await res.json();
    setListingCategories(data.listingCategories);
  };

  return (
    <Stack gap={3} className="mainContainer">
      <header className="App-header p-2">
        Explore alternative tags for your listings. Search for similar listings
        by selecting a category.
      </header>
      <div className="mx-auto accordionContainer">
        <SearchListingsForm
          setListings={setListingsByKeywords}
          listingCategories={listingCategories}
          byCategory="True"
        />
        <SearchListingsResults
          listings={listingsByKeywords}
          listingCategories={listingCategories}
        />
      </div>
    </Stack>
  );
}

export default TagsCategory;
