import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
ReactGA.initialize("G-V7GDPRQY4K");

reportWebVitals();
