import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import "../App.css";

function NavigationBar() {
  return (
    <Navbar bg="primaryColour" data-bs-theme="light" sticky="top">
      <Container>
        <Navbar.Brand href="/">DumStats</Navbar.Brand>
        <Nav className="me-auto">
          <NavDropdown title="Tags" id="basic-nav-dropdown">
            <NavDropdown.Item href="#tags/category">
              Search by category
            </NavDropdown.Item>
            <NavDropdown.Item href="#tags/keywords">
              Search by keywords
            </NavDropdown.Item>
            {/* <NavDropdown.Item href="#tags/listing">
              Search by listing id
            </NavDropdown.Item> */}
          </NavDropdown>
          <Nav.Link href="#/tracking">Tracking</Nav.Link>
          <Nav.Link href="#/contactus">Contact us</Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
