import "./Tracking.css";
import { useState, useEffect } from "react";
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import { BarChart } from "@mui/x-charts/BarChart";
import dayjs from "dayjs";

import ReactGA from "react-ga4";

function Tracking() {
  const [loadingTracking, setLoadingTracking] = useState(false);
  const [listingId, setListingId] = useState("");
  const [dates, setDates] = useState(null);
  const [views, setViews] = useState(null);
  const [favorers, setFavorers] = useState(null);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/tracking",
      title: "Tracking page",
    });
  }, []);

  const handleFormChange = (e) => setListingId(e.target.value);

  const getViewsAndFavorersFromTrackingDetails = (trackingDetails) => {
    var dates = [];
    var views = [];
    var favorers = [];
    trackingDetails.forEach((entry) => {
      dates.push(dayjs(entry.date, "YYYY-MM-DD"));
      views.push(entry.views);
      favorers.push(entry.favorers);
    });
    // console.log(`${dates}`);
    // console.log(`${views}`);
    setDates(dates);
    setViews(views);
    setFavorers(favorers);
  };

  const getTracking = async (event) => {
    event.preventDefault();
    setLoadingTracking(true);
    console.log(`Called getTracking with: ${listingId}`);

    const res = await fetch(`/api/tracking?listingId=${listingId}`);
    const data = await res.json();

    console.log(`Response: ${JSON.stringify(data.trackingDetails)}`);
    getViewsAndFavorersFromTrackingDetails(data.trackingDetails);
    setLoadingTracking(false);

    ReactGA.event({
      category: "Button Click",
      action: "Called getTracking",
      label: "Tracking Page",
    });
  };

  return (
    <Stack gap={3} className="mainContainer">
      <header className="App-header p-2">
        Track listing changes and performance (ranking, views, favorers)
      </header>
      <div className="mx-auto accordionContainer">
        <div className="searchContainer d-flex justify-content-center align-items-center">
          <Form
            className="rounded p-4 p-sm-3 d-flex flex-row"
            onSubmit={getTracking}
          >
            <Form.Control
              placeholder="Enter listing id"
              value={listingId}
              onChange={handleFormChange}
            />
            <Button
              variant="secondary"
              type="submit"
              disabled={loadingTracking}
              className="text-nowrap"
            >
              {!loadingTracking ? (
                "Search"
              ) : (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Form>
        </div>
        {dates ? (
          <>
            <div className="w-100">
              <BarChart
                yAxis={[{ label: "Daily views" }]}
                xAxis={[
                  {
                    label: "Date",
                    scaleType: "band",
                    data: dates,
                    valueFormatter: (date) => dayjs(date).format("MMM D"),
                  },
                ]}
                series={[{ data: views }]}
                height={300}
              />
            </div>
            <div className="w-100">
              <BarChart
                yAxis={[{ label: "Total favorited" }]}
                xAxis={[
                  {
                    label: "Date",
                    scaleType: "band",
                    data: dates,
                    valueFormatter: (date) => dayjs(date).format("MMM D"),
                  },
                ]}
                // yAxis={[{ label: "Total favorited" }]}
                series={[{ data: favorers, color: "#fdb462" }]}
                height={300}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </Stack>
  );
}

export default Tracking;
