import { useEffect } from "react";
import ReactGA from "react-ga4";

function Contact() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/tags", title: "Contact page" });
  }, []);

  return <p>For any queries contact us at: dumstats247@gmail.com</p>;
}

export default Contact;
