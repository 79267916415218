import "../../App.css";
import "./Home.css";
import ListGroup from "react-bootstrap/ListGroup";
import "bootstrap/dist/css/bootstrap.min.css";

<script
  src="https://cdn.jsdelivr.net/npm/react/umd/react.production.min.js"
  crossorigin
></script>;

function Home() {
  return (
    <div className="homeContainer">
      <header className="App-header">
        <p>Welcome</p>
      </header>
      <p>What you can do with DumStats:</p>
      <ListGroup variant="flush">
        <ListGroup.Item variant="success">
          Explore alternative tags - search by category
        </ListGroup.Item>
        <ListGroup.Item variant="success">
          Explore alternative tags - search by keywords
        </ListGroup.Item>
      </ListGroup>
      <br />
      <p>Upcoming functionality:</p>
      <ListGroup variant="flush">
        <ListGroup.Item variant="primary">
          Track listing changes and performance (ranking, views, favorers)
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
}

export default Home;
